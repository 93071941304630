<template>
  <v-row>
    <!--PROGRESS BAR-->
    <!--<v-col cols="12">
        <PaymentStepper
          :step="1"
          :isPremi="true"
          class="mx-0 mx-sm-auto mt-3 mt-sm-6"
      /></v-col>-->
    <v-col cols="12">
      <v-row class="justify-lg-space-between">
        <!--INFORMATION AND TEXT-->

        <v-col cols="12" lg="8">
          <div v-if="category">
            <CategoryTitle :category="category" />
          </div>
          <div class="mb-4">
            <p>{{ $t("awards.booking.subtitle.1") }}</p>
            <p>{{ $t("awards.booking.subtitle.2") }}</p>
            <p>{{ $t("awards.booking.subtitle.3") }}</p>
            <p class="font-weight-bold">
              {{ $t("awards.booking.subtitle.4") }}
            </p>
          </div>
        </v-col>
        <!--CART-->
        <v-col cols="12" lg="3">
          <div class="accent rounded-sm px-3">
            <h2>{{ $t("awards.booking.cart.title") }}</h2>
            <div class="d-flex justify-space-between">
              <p class="font-weight-bold">
                {{ $t("awards.booking.cart.totalArticles") }}
              </p>
              <p class="font-weight-bold">
                {{ list?.cartItems?.length }}
              </p>
            </div>
            <div class="d-flex justify-space-between">
              <p class="font-weight-bold">
                {{ $t("awards.booking.cart.totalPoints") }}
              </p>
              <p class="font-weight-bold">7</p>
            </div>
            <div class="d-flex justify-space-between">
              <p class="font-weight-bold">
                {{ $t("awards.booking.cart.total") }}
              </p>
              <p class="font-weight-bold">9</p>
            </div>
          </div>
        </v-col>
      </v-row>
      <!--PREMI CART ITEMS-->
      <v-row>
        <v-col cols="12" lg="8" class="mb-5">
          <CreateOfferList v-if="listId" :listId="listId" />
        </v-col>
      </v-row>
    </v-col>
    <!--PAGE DIVIDER-->
    <div class="d-flex w-100 premi-catalog-slider align-center my-5">
      <v-divider class="primary"></v-divider>
      <div class="d-flex align-center px-2 px-sm-5">
        <span class="premi-catalog-slider-title font-weight-bold mr-3"
          >Catalogo premi</span
        ><span>{{
          `${this.$dayjs().year()} - ${this.$dayjs()
            .add(1, "year")
            .year()}`
        }}</span>
      </div>
      <v-divider class="primary flex-sm-grow-5"></v-divider>
    </div>
    <!-- PRODUCT SLIDER -->
    <v-col cols="12">
      <ProductListSlider
        class="w-100"
        cols="1."
        v-if="productList && productList.length > 0"
        :isCreateOffer="true"
        :productList="productList"
        :paginationClass="'swiper-pagination-'"
      />
    </v-col>
  </v-row>
  <!-- <div class="booker-awards-container" v-if="category">
     <v-btn
        class="mt-5"
        outlined
        color="primary"
        depressed
        :to="{ name: 'Awards' }"
      >
        <v-icon small class="mr-2">$prev</v-icon>
        {{ $t("awards.booking.goBackBtn") }}
      </v-btn>
    </div>-->
</template>
<style lang="scss">
@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .premi-catalog-slider {
    &-title {
      font-size: 32px;
    }
    .v-divider:last-of-type {
      flex-grow: 5 !important;
    }
  }
}
</style>
<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import CreateOfferList from "@/components/product/createOffert/CreateOfferList.vue";
import categoryMixins from "~/mixins/category";
import ProductListSlider from "@/components/product/ProductListSlider.vue";
import ProductService from "~/service/productService";
import customService from "@/service/customService";

//import PaymentStepper from "@/components/payment/PaymentStepper.vue";
import { mapGetters } from "vuex";
import get from "lodash/get";

export default {
  name: "Booking",
  components: {
    // PaymentStepper,
    CategoryTitle,
    ProductListSlider,
    CreateOfferList
  },
  mixins: [categoryMixins],
  props: { storeId: { type: Number, required: false } },
  data() {
    return {
      list: null,
      listId: null,
      cartItem: null,
      productList: null
    };
  },
  computed: {
    ...mapGetters({
      user: "cart/getUser"
    })
  },
  methods: {
    goBack() {
      this.$router.push("/premiali");
    },
    async fetchList() {
      this.list = await customService.getList(8, false);
    },
    async fetchAwardsProductList(isAddMore = false) {
      let _this = this;
      this.loading = true;
      console.log("this.category", this.category);
      let res = await ProductService.search({
        store_id: 8,
        q: this.wordSearched,
        page: this.page,
        page_size: process.env.VUE_APP_PRODUCT_LIST_PAGE_SIZE,
        sort: this.sortFilter
      });
      if (res && res.products) {
        if (isAddMore) {
          let prodList = this.productList.concat(res.products);
          _this.productList = prodList;
        } else {
          this.productList = res.products;
        }
        this.pager = res.page;
      } else {
        this.productList = [];
      }
      this.key++;
      this.loading = false;
    }
  },
  created() {
    this.fetchList();
  },
  mounted() {
    this.listId = get(this.$route, "params.listId");
    this.fetchAwardsProductList();
  }
};
</script>
