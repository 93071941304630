<template>
  <v-list-item v-if="item" class="booking-list-item py-5" flat>
    <v-row class="flex-lg-nowrap justify-space-between align-center">
      <v-col cols="4" sm="auto" md="1">
        <!-- prodotto -->
        <v-list-item-avatar
          width="80"
          height="80"
          rounded="0"
          class="ma-0 ml-n2"
        >
          <img
            :src="item.product.mediaURL"
            :alt="item.product.name"
            onerror="this.onerror=null;this.src='/no-icon.png'"
          />
        </v-list-item-avatar>
      </v-col>
      <v-col cols="8" sm="5" md="4">
        <v-list-item-content class="pa-0 pa-sm-2">
          <div class="font-weight-bold">
            {{ item.product.name }}
          </div>
          <div class="short-descr">
            {{ item.product.shortDescr }}
          </div>
        </v-list-item-content>
      </v-col>
      <v-col
        cols="12"
        sm="4"
        md="3"
        class="qty-wrap-col text-center d-flex justify-end justify-sm-center my-3 my-sm-0"
      >
        <!-- quantità -->
        <template v-if="isActive">
          <ProductQty
            class="mx-auto"
            :product="product"
            :showCartButton="false"
          />
        </template>
        <div v-else class="not-available">
          {{ $t("product.notAvailable") }}
        </div>
      </v-col>
      <v-col cols="6" md="3" class="gross-total text-left font-weight-bold">
        <!-- contributo -->

        <span v-html="userContributionType"></span>
        <!--<template v-if="item.promoPoints">
          {{ $t("awards.products.freeWithPoints") }} {{ item.promoPoints }}
          {{ $t("awards.common.points") }}
        </template>

        <template v-if="item.product.priceDisplay">
          + {{ $n(item.product.priceDisplay, "currency") }}
        </template>-->
      </v-col>
      <v-col class="actions text-right" md="1">
        <!-- actions -->
        <v-btn outlined color="primary" v-on:click.prevent="removeItem()">
          <v-icon>$delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-list-item>
</template>
<style lang="scss" scoped>
.booking-list-item {
  border-bottom: 1px solid var(--v-grey-lighten1);
  &:last-of-type {
    border-bottom: none;
  }
  .qty-wrap-col {
    .qty-wrap {
      min-width: 200px;
    }
  }
}
</style>
<script>
import ProductQty from "@/components/product/ProductQty.vue";
import CatalogService from "~/service/catalogService";
import CartService from "~/service/cartService";
import { mapActions } from "vuex";

export default {
  name: "BookingWishlistListItem",
  components: { ProductQty },
  props: ["item", "isActive"],
  data() {
    return {
      product: {}
    };
  },
  computed: {
    quantity() {
      // let item = this.item;
      if (this.item === null || this.item.product === null) return 0;
      if (this.item.product.productInfos.TIPOLOGIA == "Sfuso") {
        //Handling floating point decimals issues
        var val = (this.item.weight * 100).toFixed(0);
        val = val * CatalogService.getMultiplier(this.item.product);
        val = val / 100;
        return val;
      } else {
        return this.item.quantity;
      }
    },
    quantityPerUnit() {
      let quantity = this.quantity;
      if (quantity === 0) return;
      if (
        this.item.product.productInfos.TIPOLOGIA === "Sfuso" &&
        this.item.product.productInfos.WEIGHT_SELLING_SINGLE_UNIT
      ) {
        try {
          var singleUnit = Math.floor(
            quantity /
              parseFloat(
                this.item.product.productInfos.WEIGHT_SELLING_SINGLE_UNIT
              )
          );
          var singleUnitUm = this.item.product.productInfos
            .WEIGHT_SELLING_SINGLE_UNIT_UM
            ? this.item.product.productInfos.WEIGHT_SELLING_SINGLE_UNIT_UM
            : "pz";
          return "(" + singleUnit + " " + singleUnitUm + ")";
        } catch (e) {
          console.log(e);
          return;
        }
      }
      if (this.item.product.productInfos.TIPOLOGIA === "Confezione") {
        try {
          let singleUnit =
            quantity *
            parseFloat(this.item.product.productInfos.WEIGHT_SELLING);
          singleUnit = (singleUnit * 1000).toFixed(0);
          singleUnit = singleUnit / 1000;
          let singleUnitUm = this.item.product.productInfos.WEIGHT_UNIT_SELLING;
          return "(" + singleUnit + " " + singleUnitUm + ")";
        } catch (e) {
          console.log(e);
          return;
        }
      }
      return null;
    },
    unit() {
      if (this.item.product.productInfos) {
        if (this.item.product.productInfos.TIPOLOGIA == "Sfuso") {
          return this.item.product.productInfos.WEIGHT_UNIT_SELLING;
        } else {
          return this.item.product.productInfos.UNIT_SELLING != null
            ? this.item.product.productInfos.UNIT_SELLING
            : "pz";
        }
      } else {
        return null;
      }
    },
    userContributionType() {
      return (
        this.item.product.warehousePromo &&
        this.item.product.warehousePromo.view.bubble
      );
    }
  },
  methods: {
    ...mapActions({
      addProduct: "cart/addProduct"
    }),
    removeItem() {
      this.$emit("removeItem", {
        itemId: this.item.itemId,
        product: this.item.product
      });
    },
    plus() {
      const newQuantity = CartService.plus(this.item.product, this.quantity);

      this.$emit("update", { newQuantity, item: this.item });
    },
    async minus() {
      const newQuantity = CartService.minus(this.item.product, this.quantity);

      let res = true;
      if (newQuantity <= 0) {
        res = await this.$dialog.confirm({
          text: global.vm.$t("awards.products.removeItemFromList")
        });
      }
      if (res) this.$emit("update", { newQuantity, item: this.item });
    },
    addToCart() {
      this.addProduct({ product: this.item.product, quantity: this.quantity });
    },
    hasPromo(item) {
      return (
        item.product.priceStandardDisplay &&
        item.unitPrice !== item.product.priceStandardDisplay
      );
    }
  }
};
</script>
