<template>
  <div class="booking-list-detail-container" v-if="list">
    <div v-if="list.cartItems.length > 0">
      <v-list class="t-detail-list" v-if="list">
        <BookingWishlistListItem
          v-for="item in list.cartItems"
          v-bind:key="item.product.productId"
          :item="item"
          :isActive="true"
          @removeItem="removeFromList"
          @update="updateList"
        />
      </v-list>
    </div>
    <div class="booking-list-detail-container-warehouse my-5">
      <legend class="font-weight-bold mb-3">
        {{ $t("awards.booking.warehouse") }}
      </legend>
      <v-select
        return-object
        :items="warehouses"
        item-text="name"
        outlined
        dense
      ></v-select>
    </div>
    <v-card
      light
      depresses
      elevation="0"
      v-if="list.cartItems.length == 0"
      class="px-3 mb-10"
    >
      <v-card-title class="px-0 py-4 font-weight-bold">{{
        $t("awards.lists.noProducts")
      }}</v-card-title>
    </v-card>

    <div class="d-flex justify-space-between">
      <v-btn
        outlined
        color="primary"
        class="default--text"
        v-on:click="goBack"
        >{{ $t("awards.booking.cta.goBack") }}</v-btn
      >
      <v-btn v-on:click.prevent="removeItem()" color="primary elevation-0">{{
        $t("awards.booking.cta.forward")
      }}</v-btn>
      <v-card-actions class="justify-end">
        <v-btn
          color="primary"
          class="mt-3 mt-md-0"
          :block="$vuetify.breakpoint.xsOnly"
          depressed
          large
          @click="bookWishlist"
          min-width="250"
        >
          {{ $t("awards.lists.options.bookBtn") }}
        </v-btn>
      </v-card-actions>
    </div>

    <!--<v-card class="book-card green lighten-2 mt-5">
      <v-card-text>
        <!- - addressID : 160 - ->
        <v-checkbox
          dense
          color="primary"
          hide-details
          class="delivery-choose"
          :label="$t('awards.lists.options.nextDrive')"
          v-model="checkbox1"
          @change="resetStoreSelected(1)"
        >
        </v-checkbox>
        <!- - addressID : 159 - ->
        <v-checkbox
          dense
          color="primary"
          hide-details
          class="delivery-choose"
          :label="$t('awards.lists.options.nextHome')"
          v-model="checkbox2"
          @change="resetStoreSelected(2)"
        >
        </v-checkbox>

        <span class="d-block mt-5 mb-2">
          <v-icon class="mr-2">$info</v-icon>
          {{ $t("awards.lists.options.info") }}
        </span>
        <v-select
          v-if="listStore && listStore.length > 0"
          :disabled="checkbox1 || checkbox2"
          v-model="storeSelected"
          outlined
          dense
          :items="listStore"
          :label="$t('awards.lists.options.warehouseSelect')"
          return-object
        >
          <!- - item-value="addressId" - ->
          <template v-slot:item="{ item }">
            <span>
              {{ item.addressName }}: {{ item.address1 }},
              {{ item.addressNumber }} {{ item.city }}
            </span>
          </template>
          <template v-slot:selection="{ item }">
            <span>
              {{ item.addressName }}: {{ item.address1 }},
              {{ item.addressNumber }} {{ item.city }}
            </span>
          </template>
        </v-select>
      </v-card-text>

      <v-card-actions class="justify-end">
        <v-btn
          color="primary"
          class="mt-3 mt-md-0"
          :block="$vuetify.breakpoint.xsOnly"
          depressed
          large
          @click="bookWishlist"
          :disabled="!checkbox1 && !checkbox2 && !storeSelected"
          min-width="250"
        >
          {{ $t("awards.lists.options.bookBtn") }}
        </v-btn>
      </v-card-actions> </v-card
    >-->
  </div>
</template>
<style lang="scss">
@media #{map-get($display-breakpoints, 'md-and-up')} {
  .booking-list-detail-container {
    &-warehouse {
      width: 50%;
    }
  }
}
</style>
<script>
import BookingWishlistListItem from "@/components/awards/BookingWishlistListItem.vue";
import { mapActions } from "vuex";
import deliveryReactive from "~/mixins/deliveryReactive";
import ListService from "~/service/listService";
import storeService from "@/commons/service/storeService";
import addressService from "@/commons/service/addressService";
import customService from "@/service/customService";

export default {
  name: "BookingWishlistList",
  props: {
    listId: { type: Number, required: true },
    storeId: { type: Number, default: 8 }
  },
  data() {
    return {
      list: null,
      panel: [],
      warehouses: null,
      storeSelected: null,
      listStore: [],
      checkbox1: false,
      checkbox2: false
    };
  },
  components: {
    BookingWishlistListItem
  },
  mixins: [deliveryReactive],

  methods: {
    ...mapActions({
      addProductsFromWishlist: "cart/addProductsFromWishlist"
    }),
    async fetchListStore() {
      this.listStore = await storeService.getListStore(this.storeId);
    },
    async findWarehouse() {
      const res = await addressService.findWarehouse();
      this.warehouses = res.warehouses.map(wh => ({
        warehouseId: wh.warehouseId,
        name: `${wh.address.addressName} - ${wh.address.address1}`
      }));
    },
    /*resetStoreSelected(checkboxId) {
      if (checkboxId == 1) {
        this.checkbox2 = false;
      } else if (checkboxId == 2) {
        this.checkbox1 = false;
      }
      this.storeSelected = null;
    },*/
    async bookWishlist() {
      let storeData = null;

      let res = await customService.confirm(this.storeId, 159);
      if (res) {
        this.$router.push({
          name: "BookingCompleted",
          params: { store: storeData }
        });
      }
    },
    async fetchList() {
      this.list = await customService.getList(this.storeId);
    },
    async removeFromList({ itemId, product }) {
      let res = await this.$dialog.confirm({
        text: global.vm.$t("message.removeItemFromList", {
          productName: product.name,
          productShortDescr: product.shortDescr
        })
      });
      if (res) {
        const newList = await customService.removeItemFromPromoList(
          product.productId,
          itemId,
          8
        );
        this.list = newList;
      }
    },
    async emptyList() {
      const textConfirm = `Sei sicuro di voler svuotare la lista ${this.list.name}?`;

      let res = await this.$dialog.confirm({
        text: textConfirm
      });
      if (res) {
        const res = await ListService.emptyList(this.list);

        this.list = res;
      }
    },
    async addAllToCart() {
      let res = await global.vm.$dialog.confirm({
        text: global.vm.$t("message.addAllToList")
      });
      if (res) {
        await this.addProductsFromWishlist(this.listId);
      }
    },
    async updateList({ newQuantity, item }) {
      const res = await customService.updatePromoInList(
        this.storeId,
        item,
        newQuantity
      );
      this.list = res;
    },
    reload() {
      this.fetchList();
    }
  },
  created() {
    this.reload();
    this.fetchListStore();
    this.findWarehouse();
  },
  watch: {
    listId() {
      this.reload();
    }
  }
};
</script>
